import type { ComponentProps, ReactNode } from "react";
import type { SimpleElements } from "../../components/renderAsText.ts";

interface TextSecretProps {
    children: SimpleElements;
    placeholder?: ReactNode;
    visible: boolean;
    withButton?: boolean;
    setVisible: (value: boolean) => void;
}
export function TextSecret({ children, placeholder, visible, withButton = false, setVisible }: TextSecretProps) {
    placeholder ??= withButton ? "****************" : "show-password";
    const content = visible ? children : placeholder;

    const props: ComponentProps<"span"> = {
        className: "color-primary cursor-pointer",
        onClick: () => setVisible(!visible),
    };

    return !withButton ? (
        <span {...props}>{content}</span>
    ) : (
        <>
            {content} <span {...props}>{visible ? "hide" : "show"}</span>
        </>
    );
}
