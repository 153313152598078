import type {
    ManagedServicesVmBody,
    ManagedServicesVmStorageReplica,
    ReplicaType,
} from "@warrenio/api-spec/spec.oats.gen";

export function getPrimaryStorage(vm: ManagedServicesVmBody) {
    return vm.resource_allocation.storage?.find((item) => item.primary);
}

export function getReplicasByType(vm: ManagedServicesVmBody, type: ReplicaType) {
    const primaryStorage = getPrimaryStorage(vm);
    return primaryStorage?.replica?.filter((item: ManagedServicesVmStorageReplica) => item.type === type) ?? [];
}

export function getReplicaByUuid(vm: ManagedServicesVmBody, replicaUuid: string) {
    const primaryStorage = getPrimaryStorage(vm);
    return primaryStorage?.replica?.find((item: ManagedServicesVmStorageReplica) => item.uuid === replicaUuid);
}
