import { createLazyFileRoute } from "@tanstack/react-router";
import { ServiceView } from "../../../../modules/services/ServiceView.tsx";

export const Route = createLazyFileRoute("/_main/services/service/$location/$serviceId/")({
    component: Component,
});

function Component() {
    const { serviceId, location } = Route.useParams();
    return <ServiceView key={serviceId} serviceId={serviceId} location={location} />;
}
