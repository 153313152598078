import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { serviceImagesByOsNameAtom } from "../serviceImagesQuery.ts";
import type { ServiceOs } from "./serviceOs.ts";

export function useServiceOs({ os_name, os_version }: ServiceOs) {
    const allImages = useSuspenseQueryAtom(serviceImagesByOsNameAtom);

    const os = allImages.get(os_name);
    const version = os?.versions.find((item) => item === os_version);
    return { os, version, title: `${os?.display_name ?? os_name} ${version}` };
}
