import type { VmStatusEnum } from "@warrenio/api-spec/spec.oats.gen";
import type { BadgeProps } from "../../components/Badge.tsx";

export const statusLabels = {
    creating: "creating",
    running: "running",
    stopping: "stopping",
    stopped: "stopped",
    deleted: "deleted",
} satisfies Record<VmStatusEnum, string>;

export const statusColors = {
    creating: "warning",
    running: "success",
    stopping: "warning",
    stopped: "error",
    deleted: "error",
} satisfies Record<VmStatusEnum, BadgeProps["color"]>;
