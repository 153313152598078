import React, { type ReactElement } from "react";

interface SimpleChildren {
    children?: SimpleElements;
}

export type SimpleElements =
    | ReactElement<SimpleChildren>
    | string
    | null
    | undefined
    | false
    | number
    | SimpleElements[];

export function simpleToText(element: SimpleElements): string {
    if (typeof element === "string") {
        return element;
    } else if (typeof element === "number") {
        return String(element);
    } else if (Array.isArray(element)) {
        return element.map(simpleToText).join("");
    } else if (element == null || element === false) {
        return "";
    } else if (React.isValidElement<SimpleChildren>(element)) {
        if (element.type === "br") {
            return "\n";
        }

        const { children } = element.props;
        const text = children ? simpleToText(children) : "";
        return element.type === "div" ? `${text}\n` : text;
    }

    console.warn("Invalid element: %o", element);
    throw new Error(`Do not know how to handle JSX: ${element}`);
}
