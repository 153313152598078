import G from "../../components/Grid.module.css";

import type { ReactNode } from "react";
import { MetricChart, PlotSizer } from "./monitor/MetricChart.tsx";
import type { VirtualMachineLoc } from "./vmQuery.ts";

export function MetricsGrid({ vm }: { vm: Pick<VirtualMachineLoc, "uuid" | "status" | "location"> }) {
    const { status, uuid, location } = vm;
    const stopped = status === "stopped";
    return (
        <div className={G.WarrenGrid}>
            <GraphContainer label="CPU (%)" stopped={stopped}>
                <MetricChart host={uuid} metric="libvirt.guest_time_per_vcpu_delta" location={location} />
            </GraphContainer>

            <GraphContainer label="RAM (MB)" stopped={stopped}>
                <MetricChart host={uuid} metric="libvirt.used_memory_kb" location={location} />
            </GraphContainer>

            <GraphContainer label="Disk I/O (MB/s)" stopped={stopped}>
                <MetricChart host={uuid} metric="libvirt.block_wr_bytes_delta" location={location} />
            </GraphContainer>

            <GraphContainer label="Bandwidth (Mbit/s)" stopped={stopped}>
                <MetricChart host={uuid} metric="libvirt.net_rx_bytes_delta" location={location} />
            </GraphContainer>
        </div>
    );
}

function GraphContainer({ children, label, stopped }: { children: ReactNode; label: ReactNode; stopped: boolean }) {
    // NB: The graph must be stretched to fill the container for the auto-scaling to work
    return (
        <div className="flex flex-col gap-2 justify-stretch">
            <div>{label}</div>
            <div className={G.Chart}>
                {stopped ? <PlotSizer>{() => <div className={G.Stopped} />}</PlotSizer> : children}
            </div>
        </div>
    );
}
