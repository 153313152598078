import T from "../../components/forms/TextField.module.css";

import type { ServiceWhitelistEntryBody } from "@warrenio/api-spec/spec.oats.gen";
import { FieldError, Input, TextField } from "react-aria-components";
import { useForm } from "react-hook-form";
import { WButton, WSubmitButton } from "../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../components/forms/FieldButtonGroup.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { addServiceWhitelistAddressMutation, type ManagedServiceLoc } from "./servicesQuery.ts";

export interface AddIpFormProps {
    obj: ManagedServiceLoc;
    onClose: () => void;
}

export function AddIpForm({ obj, onClose }: AddIpFormProps) {
    const mutation = useStandardMutation(addServiceWhitelistAddressMutation);

    /// Form
    const form = useForm<ServiceWhitelistEntryBody>({});
    const { register, getValues } = form;

    return (
        <WHookForm
            form={form}
            onSubmit={async () => {
                await mutation.mutateAsync({ uuid: obj.uuid, body: { ip_address: getValues("ip_address") } });
                onClose();
            }}
        >
            <TextField isRequired type="text" aria-label="IP address" className={T.TextField}>
                <FieldButtonGroup>
                    <Input className={T.Input} placeholder="IP address" autoFocus={true} {...register("ip_address")} />
                    <WSubmitButton icon="jp-icon-add">Add</WSubmitButton>
                    <WButton action={onClose}>Close</WButton>
                </FieldButtonGroup>
                <FieldError />
            </TextField>
        </WHookForm>
    );
}
