import RA from "../../components/forms/Radio.module.css";
import R from "../../components/forms/Radiogroup.module.css";

import { useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteIpAddressMutation, ipQueryAtom } from "../network/ipAddress/apiOperations.ts";
import type { ManagedServiceLoc } from "./servicesQuery.ts";
import type { DeleteServiceMutation } from "./ServiceView.tsx";

export interface ServiceDeleteModalProps {
    managedService: ManagedServiceLoc;
    deleteMutation: DeleteServiceMutation;
}

export function ServiceDeleteModal({ managedService, deleteMutation }: ServiceDeleteModalProps) {
    //#region Hooks
    const delIpMutation = useStandardMutation(deleteIpAddressMutation);
    const { uuid, display_name, properties } = managedService;
    const ips = useSuspenseQueryAtom(ipQueryAtom(properties.location));
    const attachedPublicIp = [...ips.values()].find(
        (ip) => ip?.assigned_to_resource_type === "service" && ip.assigned_to === uuid,
    );

    const [deleteFloating, setDeleteFloating] = useState<string>("");
    //#endregion

    const deleteAlsoIp = deleteFloating === "yes" && attachedPublicIp;

    async function deleteAction() {
        await deleteMutation.mutateAsync({ uuid: uuid });
        if (deleteAlsoIp) {
            await delIpMutation.mutateAsync({ location: properties.location, address: attachedPublicIp.address });
        }
    }

    return (
        // TODO: Use a shared visual component with `DeleteLoadBalancerModal`
        <DeleteModal
            title="Delete Resource"
            modalAction={deleteAction}
            isActionDisabled={attachedPublicIp && deleteFloating === ""}
        >
            <p className="pb-3">
                Deleting a service "{display_name}" will permanently remove it and will not be accessible after that.
            </p>
            <p className="pb-3">
                Deleting a service will also delete your backups and the service cannot be restored after that.
            </p>

            {!!attachedPublicIp && (
                <>
                    <p className="pb-3">
                        You have a floating IP <b>{attachedPublicIp.address}</b> assigned to this resource.
                    </p>

                    <p className="font-size-subtitle pb-2">What would you like to do?</p>
                    <RadioGroup
                        className={R.RadioGroup}
                        aria-label="Keep or delete floating IP"
                        value={deleteFloating}
                        orientation="horizontal"
                        onChange={setDeleteFloating}
                    >
                        <Radio className={RA.Radio} value="no">
                            Keep floating IP
                        </Radio>
                        <Radio className={RA.Radio} value="yes">
                            Delete floating IP
                        </Radio>
                    </RadioGroup>
                </>
            )}
        </DeleteModal>
    );
}
