import { DetailWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { ServiceImageIcon } from "../ServiceImageIcon.tsx";
import type { ManagedServiceLoc } from "../servicesQuery.ts";
import { extractServiceOs } from "./serviceOs.ts";
import { useServiceOs } from "./useServiceOs.ts";

export function ServiceOsInfo({ obj }: { obj: ManagedServiceLoc }) {
    const { os, title } = useServiceOs(extractServiceOs(obj));

    return (
        <DetailWithIcon title={title} icon={<ServiceImageIcon image={os} className="color-primary size-0.75rem" />} />
    );
}
